<template>
	<div class="locker_setting_list sub_new_style01 sub_ui_box1">
		<Search
			codeKey="search_infopush_user"
			:searchObj="searchType.obj"
			:useKeywordType="true"
			@change-search-type="$_changeSearchType"
			@onSearchClick="$_searchData($event, 'search_infopush_user')"
		>
			<template v-slot:before>
				<DxSelectBox
					v-model="searchType.customTypes.siteId"
					placeholder="사이트 선택"
					:items="getSiteList"
					display-expr="siteNm"
					value-expr="siteId"
					:styling-mode="stylingMode"
					:width="120"
					:height="30"
					@value-changed="$_changeSearchCustomType('siteId', $event)"
				/>
			</template>
		</Search>
		<esp-dx-data-grid :data-grid="dataGrid" ref="userGrid">
		</esp-dx-data-grid>
		<DxPopup
			v-model="modal.isOpened"
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:width="modal.initData ? modal.initData.width : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component
						ref="modalRef"
						v-if="modal.sendData"
						:is="modal.currentComponent"
						:modalData="modal.sendData"
						:isModal="modal.isModal"
						:sendData="modal.sendData"
					/>
				</div>
			</template>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.hasOwnProperty('save') : !modal.initData.buttons.hasOwnProperty('save') ) : false"
				:options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.save.text : '' ) : '',
						width: '120',
						height: '40',
						onClick: () => selectUsers()
					}"
			/>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.hasOwnProperty('cancel') : !modal.initData.buttons.hasOwnProperty('cancel') ) : false"
				:options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.cancel.text : '' ) : '',
						width: '120',
						height: '40',
						onClick: () => {
							isOpenModal(false);
						}
					}"
			/>
		</DxPopup>
	</div>

</template>

<script>
import Search from '@/components/common/search.vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalAddUser from './modal-add-user.vue';
import { isSuccess } from '@/plugins/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = this;

export default {
	components: {
    EspDxDataGrid,
		DxPopup,
		DxToolbarItem,
		ModalAddUser,
		DxSelectBox,
		Search,
	},
	data() {
		return {
			infoPushSiteList: [],
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				sendData: null,
			},
			contentList: [],
			userList: [],
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					siteId: null,
					inContentId: null,
					outContentId: null,
				},
				paramsData: null,
			},
			dataGrid: {
				refName: 'userGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				// height:'500',    // 주석처리시 100%
				apiActionNm: {
				},
				customEvent: {},
				showActionButtons: {
					customButtons: [
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '등록',
								elementAttr: { class: 'btn_XS default filled add1' },
								width: 60,
								height: 30,
								onClick() {
									vm.onChangeUsers();
								},
							},
							location: 'before',
						},
						{
							widget: 'dxButton',
							options: {
								icon: '',
								text: '삭제',
								elementAttr: { class: 'btn_XS white light_filled trash' },
								width: 60,
								height: 30,
								onClick() {
									vm.onDeleteData();
								},
							},
							location: 'before',
						},
					],
				},
				isDuplicateConfigKey: false, //설정키 중복 체크
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: false, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					enabled: true,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: false,
				},
				headerFilter: {
					visible: false,
				},
				editing: {
					allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '사번',
						dataField: 'userNo',
						width: 150,
						height: 40,
						alignment: 'center', // left center right
						visible: true,
						allowEditing: false,
						sortOrder: 'none', // acs desc none
						allowHeaderFiltering: false,
						allowExporing: true,
						fixed: false, // 컬럼 fix 시 사용
						fixedPosition: 'left', // left or right
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								vm.onChangeUsers(options.data);
							});
							container.append(aTag);
						},
					},
					{
						caption: '이름',
						dataField: 'user.userNm',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
					},
					{
						caption: '내선번호',
						dataField: 'user.ext',
						width: 150,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
					},
					{
						//caption: '청',
						dataField: 'user.deptNmDepth1',
						calculateCellValue: (rowData) => this.deptNmDepth(rowData, 'deptNmDepth1'),
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
						headerCellTemplate: container => {
							let div = document.createElement('div');
							div.innerHTML = `<b>${this.deptTitleByDepth('depth1')}</b>`;
							container.append(div);
						},
					},
					{
						//caption: '서',
						dataField: 'user.deptNmDepth2',
						calculateCellValue: (rowData) => this.deptNmDepth(rowData, 'deptNmDepth2'),
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
						headerCellTemplate: container => {
							let div = document.createElement('div');
							div.innerHTML = `<b>${this.deptTitleByDepth('depth2')}</b>`;
							container.append(div);
						},
					},
					{
						//caption: '과',
						dataField: 'user.deptNmDepth3',
						calculateCellValue: (rowData) => this.deptNmDepth(rowData, 'deptNmDepth3'),
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
						headerCellTemplate: container => {
							let div = document.createElement('div');
							div.innerHTML = `<b>${this.deptTitleByDepth('depth3')}</b>`;
							container.append(div);
						},
					},
					{
						//caption: '팀',
						dataField: 'user.deptNmDepth4',
						calculateCellValue: (rowData) => this.deptNmDepth(rowData, 'deptNmDepth4'),
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
						headerCellTemplate: container => {
							let div = document.createElement('div');
							div.innerHTML = `<b>${this.deptTitleByDepth('depth4')}</b>`;
							container.append(div);
						},
					},
					{
						caption: '수신시 설정',
						dataField: 'inContent.description',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
					},
					{
						caption: '발신시 설정',
						dataField: 'outContent.description',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						allowExporing: true,
					},
				],
			},
		};
	},
	computed: {
		/** @description: U_CODE svr_type 가져오기 */
		getSiteList(){
			const currentPath = this.$router.currentRoute.path;
			const store = this.$store.getters.getSearchHists[currentPath] || [];
			const params = store[0]?.search;

			if(params?.siteId) {
				this.setCustomTypes('siteId', params.siteId);
			} else {
				this.setCustomTypes('siteId', null);
			}

			return [{ siteId: null, site:'전체', siteNm: '전체' }, ...this.infoPushSiteList];
		},
		/** @description: 부서 타이틀 가져오기 */
		deptTitles() {
			return this.$_getCode('euc_dept_title').filter(d => d.delFl === 'N');
		},
	},
	methods: {
		/** @description: 목록에서 선택된 데이터 삭제하는 메서드 */
		async onDeleteData() {
			const selectedRows = this.$refs.userGrid.selectedRowsData;
			if(!selectedRows?.length) {
				this.$_Msg("대상이 선택되어 있지 않습니다.");
			} else {
				if(await this.$_Confirm("선택한 데이터를 삭제하시겠습니까?")) {
					const payload = {
						actionname: 'INFOPUSH_USER_DELETE',
						data: {
							data: {
								data: selectedRows
							}
						}
					}

					const res = await this.CALL_API(payload);
					if(res.status === 200) {
						this.$refs.userGrid.getGridInstance.refresh();
					}
				}
			}
		},
		/** @description: Modal 데이터 선택 후 적용 시 선택된 데이터 불러오는 메서드 */
		selectUsers() {
			const siteId = this.$refs.modalRef.siteId;
			let inContentId = this.$refs.modalRef.inContentId;
			let outContentId = this.$refs.modalRef.outContentId;

			if(!siteId) {
				this.$_Msg('사이트를 선택해주세요.');
				return;
			} else if(!inContentId && !outContentId) {
				this.$_Msg('설정 유형 선택이 잘못되었습니다.');
				return;
			}

			if(!inContentId || inContentId === 'isNull') {
				inContentId = null;
			}

			if(!outContentId || outContentId === 'isNull') {
				outContentId = null;
			}

			const selectUsers = this.$refs.modalRef.getSelectedUserData().map((item) => {
				return { siteId, userNo: item.userNo, inContentId, outContentId };
			});

			if(!selectUsers?.length) {
				this.$_Msg("대상이 선택되어 있지 않습니다.");
				return;
			}

			this.onUpdateUser(selectUsers);
		},
		/** @description: 선택된 데이터 등록 및 수정 API 호출 메서드 */
		async onUpdateUser(users) {
			const payload = {
				actionname: 'INFOPUSH_USER_UPDATE',
				data: {
					data: users
				},
				loading: true,
			}

			const res = await this.CALL_API(payload);
			if(res.status === 200 && res.data.header.resCode === 'success') {
				await this.$_Msg("저장되었습니다.");
				this.isOpenModal(false);
				this.$refs.userGrid.getGridInstance.refresh();
			}
		},
		/** @description: 문구 버튼 메서드 */
		async onChangeUsers(userData = {}) {
			const sendData = { contentList: this.contentList, userData }
			await this.onOpenModal(
				'ModalAddUser',
				{
					title: `사용자 추가`,
					//buttons: null,
					width: '1400',
					height: '800',
					buttons:{
						save : { text: '적용' },
						cancel : { text: '닫기' },
					}
				},
				sendData
			);
		},
		/** @description: 팝업 창 열때 이벤트 */
		onOpenModal(componentNm, componentInitData, sendData){
			this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
			this.modal.initData = componentInitData;  //set init modal templet
			this.modal.sendData = sendData;
			this.modal.isModal = true;
			this.isOpenModal(true);
		},
		/** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if(!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
				this.modal.target = null;
			}
		},
		/** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
		setCustomTypes(key, value) {
			this.searchType.customTypes[key] = value;
		},
		/** @description: 뎁스별 부서 타이틀 */
		deptTitleByDepth(depth) {
			return this.deptTitles.find(d => d.codeValue === depth).codeNm;
		},
		/** @description: 부서 출력 */
		deptNmDepth(rowData, deptNmDepth) {
			const dept = rowData?.user[deptNmDepth];
			if (dept) {
				return dept;
			} else {
				return '-';
			}
		},
		/** @description: 데이터 조회 메서드 */
		async selectDataList(sort = '-regDt') {
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				load: async (loadOptions) => {
					let params = this.$_getDxDataGridParam(loadOptions);
					if (!params.sort) {
						params.sort = sort;
					}

					params = { ...params, ...this.searchType.paramsData };

					const payload = {
						actionname: 'INFOPUSH_USER_LIST_ALL',
						data: {
							params
						},
						loading: false,
					};

          const rtnData = {
            data: [],
            totalCount: 0,
          };

					const res = await this.CALL_API(payload);
					if (isSuccess(res)) {
            rtnData.data = res.data.data;
            rtnData.totalCount = res.data.header.totalCount;
					}
          return rtnData;
				},
			});
		},
		/** @description: 인포푸시 content 목록 불러오는 메서드 */
		async selectContentList() {
			const payload = {
				actionname: 'INFOPUSH_CONTENT_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id
					}
				},
				loading: false,
			};

			const res = await this.CALL_API(payload);
			if (res.status === 200 && res.data.header.resCode === 'success') {
				this.contentList = [
					{ id: 'isNull', description: '미설정' },
					...res.data.data
				];
			}
		},
		async getInfoPushSiteList() {
			const payload = {
				actionname: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
						viewCd: this.$_getUsedCode.id,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				if(res.data.data.length) {
					this.infoPushSiteList = res.data.data
				} else {
					this.infoPushSiteList = [];
				}
			}
		},
		createdData() {
			vm = this;
		},
		mountData() {
			this.getInfoPushSiteList();
			this.selectContentList();
			this.selectDataList();
		}
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
